import styled from "@emotion/styled";

const Image = styled.div`
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`;

export const MintDateWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderImg = ({ children }) => (
  <Image>
    <img
      alt="Header"
      src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/earc-house-night-tiny.png?alt=media&token=c375159f-fce4-475b-bcd2-4a437a3f3127"
    />
    {children}
  </Image>
);

const HeaderImage = () => (
  <HeaderImg>
  </HeaderImg>
);

export const Panel = styled.div`
  padding: 2%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
`;

export default HeaderImage;

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import EARC from "./../abi/EARCSimple.json";

import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "firebase/functions";
import { Content } from "../App";
import Header from "./Header";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import ClosetImg from "./../assets/closet.gif";
import { useToast } from "@chakra-ui/react";
import Footer from "./Footer";
import { useEthers } from "@usedapp/core";
import ConnectWallet from "./ConnectButton";
import { ethers } from "ethers";
import styled from "@emotion/styled";

const oneOfone = [220, 254, 349, 821, 1563, 1814, 2449, 3347, 4444, 4641];

const getZauberURL = (id) => {
  return `https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/zauberkugel%2F${id}.png?alt=media`;
};

const contractAddress = "0x9ee36cD3E78bAdcAF0cBED71c824bD8C5Cb65a8C";

const MySelect = styled(Select)`
  option {
    background: black; !important
  }
`

const Closet = ({ fbIsInit }) => {
  const { account, library, chainId } =
    useEthers();

  const toast = useToast();

  const [tokenId, settokenId] = useState(null);
  const [cloth, setCloth] = useState([]);
  const [closet, setCloset] = useState({});

  const [loading, setLoading] = useState(false);
  const [closetID, setClosetID] = useState(null);

  const [balance,setBalance] = useState(0);

  const handleInputChange = (e) => settokenId(parseInt(e.target.value));

  const changeCloth = useCallback(
    (e) => {
      const values = e.target.value.split("-");
      const children = closet[values[0]].children.map((item) => item.index);
      const newCloth = cloth.filter(
        (n) => children.indexOf(parseInt(n)) === -1
      );
      if (values[1] === "default") {
        setCloth(newCloth);
      } else {
        setCloth([...newCloth, parseInt(values[1])]);
      }
    },
    [cloth, closet]
  );

  useEffect(() => {
    if(chainId && chainId !== 1) {
      toast({
        title: "Wrong Chain",
        description: "Please switch to the Ethereum Mainchain",
        status: "error",
        duration: 20000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    
  }, [chainId,toast])
  

  const createCloset = useCallback(
    (input) => {
      if (fbIsInit) {
        setClosetID(null);
        setLoading(true);
        const functions = getFunctions();
        // connectFunctionsEmulator(functions, "localhost", 5001);
        const createCloset = httpsCallable(functions, "createLayerFile");

        createCloset(input)
          .then((result) => {
            setClosetID(result.data);
          })
          .catch((error) => {
            console.error(error);
            toast({
              title: "Ups!",
              description: "Sorry. Something went wrong.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "bottom-right",
            });
          });
      }
    },
    [fbIsInit, toast]
  );

  useEffect(() => {
    if (fbIsInit) {
      const analytics = getAnalytics();
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: "/closet",
        page_title: "CLOSET",
      });
    }
  }, [fbIsInit]);

  useEffect(() => {
    let unsub;
    if (fbIsInit && closetID) {
      const db = getFirestore();
      unsub = onSnapshot(doc(db, "zauberkugel", closetID), (doc) => {
        const data = doc.data();
        if (data.id === closetID && data.status === "DONE") {
          setLoading(false);
        }
      });
    }
    return () => {
      if (unsub) unsub();
    };
  }, [closetID, fbIsInit]);

  useEffect(() => {
    if (fbIsInit) {
      async function getCloset() {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, "closet"));
        const closet = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          closet.push(doc.data());
        });
        const transformed = closet.reduce((acc, cur, index, arr) => {
          const children = arr.filter((item) => item.category === cur.category);
          return {
            ...acc,
            [cur.category]: {
              name: cur.catName,
              children,
            },
          };
        }, {});
        setCloset(transformed);
      }

      getCloset();
    }
  }, [fbIsInit]);

  useEffect(() => {
    if (tokenId) {
      if (oneOfone.indexOf(tokenId) > -1) {
        toast({
          title: "Cant dress Special EARCs",
          description: "The closet won't work on One of One NFTs. Sorry",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    }
  }, [tokenId, toast]);

  useEffect(() => {
    if (library && account){
      const call = async () => {
        const signer = library.getSigner();
        const contract = new ethers.Contract(contractAddress, EARC.abi, signer);
        const balanceOfBigNumber = await contract.balanceOf(account);
        const balanceOf = ethers.utils.formatUnits(balanceOfBigNumber, 0);
        setBalance(parseInt(balanceOf));
      }
      call()
    }
  }, [library, account])
  

  if (!account) {
    return (
      <Content>
        <Header />
        <Heading fontSize={[16, 24]} fontStyle={"italic"} fontWeight={100}>
          *Please connect your wallet first
        </Heading>
        <Box my={4}>
          <ConnectWallet withAddress={true} />
        </Box>
      </Content>
    );
  }

  if (balance < 1) {
    return (
      <Content>
        <Header />
        <Heading fontSize={[16, 24]} fontStyle={"italic"} fontWeight={100}>
          This wallet does <b>not</b> own an EARC.
        </Heading>
        <Box my={4}>
          <ConnectWallet withAddress={true} />
        </Box>
      </Content>
    );
  }

  return (
    <Content>
      <Header />
      <Heading fontStyle={"italic"} color="#F86663" fontWeight={800}>
        EARC CLOSET
      </Heading>
      <Box my={4}>
        <ConnectWallet withAddress={true} />
      </Box>
      <Flex
        w="80%"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box p={5} flex={1} minW={300}>
          <FormControl px={[0, 1, 2]} isRequired={true}>
            <FormLabel>TokenID</FormLabel>
            <Input
              size="sm"
              type="number"
              value={tokenId || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <Flex flexWrap={"wrap"}>
            {Object.values(closet).map((item) => {
              return (
                <FormControl
                  key={item.name}
                  w={["100%", "50%", "30%", "25%"]}
                  p={[0, 1, 2]}
                  my={1}
                >
                  <FormLabel>{item.name}</FormLabel>
                  <MySelect
                    colorScheme={"red"}
                    textColor={"white"}
                    variant="outline"
                    onChange={changeCloth}
                  >
                    <option
                      key={item.name}
                      value={`${item.children[0].category}-default`}
                    >
                      default
                    </option>
                    {item.children.map((item) => (
                      <option
                        key={`${item.name}-${item.index}`}
                        value={`${item.category}-${item.index}`}
                      >
                        {item.name}
                      </option>
                    ))}
                  </MySelect>
                </FormControl>
              );
            })}
          </Flex>
        </Box>
        <Box p={5} maxW={[200]}>
          {!tokenId || tokenId > 4999 ? (
            <Image borderRadius={8} src={ClosetImg} />
          ) : null}
          {tokenId && tokenId < 5000 ? (
            <Image
              borderRadius={8}
              src={`https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/ldKKljZ7-fixed-small%2F${tokenId}.png?alt=media`}
            />
          ) : null}
        </Box>
      </Flex>
      <Box w="80%" p={5}>
        <Button
          colorScheme={"red"}
          w="100%"
          disabled={
            chainId !== 1 || !tokenId || oneOfone.indexOf(tokenId) > -1 || cloth.length === 0
          }
          onClick={async () => {
            createCloset({
              tokenId,
              replace: cloth,
            });
          }}
        >
          DRESS ME
        </Button>
      </Box>

      <Flex
        m={5}
        justifyContent={"center"}
        alignItems={"center"}
        w={"80%"}
        textAlign={"center"}
      >
        {loading && (
          <Box borderRadius={15} position={"relative"}>
            <Skeleton isLoaded={!loading} h={600} w={600} />
            <MyProgress />
          </Box>
        )}
        {closetID && !loading && (
          <Box borderRadius={15} maxW={600}>
            <Image src={getZauberURL(closetID)} />
          </Box>
        )}
      </Flex>
      <Footer />
    </Content>
  );
};

const MyProgress = () => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress + 9.5 <= 100) {
        setProgress((prev) => prev + 9.5);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [progress]);

  return (
    <CircularProgress
      color="red.400"
      top={300}
      position={"absolute"}
      value={progress}
    />
  );
};

export default Closet;

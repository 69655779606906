import {
  Button,
  ChakraProvider,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useEthers, useLookupAddress } from "@usedapp/core";

import styled from "@emotion/styled";
import { walletconnect } from "../lib/connectors";

const Wrapper = styled.div`
  padding: 1% 5%;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  height: 100%;
  width: auto;
`;
const Inside = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  img {
    padding: 4%;
  }
`;

const ButtonText = styled.div`
  font-weight: 400;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 3%;
`;

const MyAddress = styled.span`
  font-size: 0.6em;
`;



function ConnectWallet({ setaddressShort, withAddress = false }) {
  const [modalopen, setmodalopen] = useState(false);
  const { activate, activateBrowserWallet, deactivate, active, account } =
    useEthers();

  const buttonClick = useCallback(() => {
    if (active) {
      deactivate();
      if (setaddressShort) {
        setaddressShort("");
      }
    } else {
      setmodalopen(true);
    }
  }, [active, deactivate, setaddressShort]);

  const buttonText = useMemo(
    () => (active ? "Disconnect Wallet" : "Connect Wallet"),
    [active]
  );

  const onClose = () => setmodalopen(false);

  const ens = useLookupAddress();
  const displayAddress = useMemo(() => {
    if (ens && ens.indexOf("0x") < 0) {
      return ens;
    }
    if (account) {
      return `${account.substr(0, 6)}...${account.substr(-4)}`;
    }
    return null;
  }, [account, ens]);

  return (
    <ChakraProvider>
      <Button
        size="md"
        backgroundColor="#F86663"
        _hover={{ bg: "#F63F3C;" }}
        borderRadius={5}
        onClick={buttonClick}
      >
        <ButtonText>
          <span>{buttonText}</span>
          {withAddress && displayAddress && (
            <MyAddress>{displayAddress}</MyAddress>
          )}
        </ButtonText>
      </Button>
      <Modal borderRadius={15} isOpen={modalopen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent backgroundColor="#292929" color="white" padding="1.5%">
          <Wrapper>
            <Button
              margin="1%"
              padding="1%"
              _hover={{ bg: "#000" }}
              backgroundColor="#141414"
              onClick={() => {
                activateBrowserWallet();
                setmodalopen(false);
              }}
            >
              <Inside>
                <div>MetaMask</div>
                <Logo
                  src="https://firebasestorage.googleapis.com/v0/b/buttheads-production.appspot.com/o/logo-metamask.png?alt=media&token=cfe421e9-bc3a-43dd-9fde-ef0ff5fd089b"
                  alt="MetaMask"
                />
              </Inside>
            </Button>
            <Button
              margin="1%"
              padding="1%"
              _hover={{ bg: "#000" }}
              backgroundColor="#141414"
              onClick={() => {
                activate(walletconnect);
                setmodalopen(false);
              }}
            >
              <Inside>
                <div>WalletConnect</div>
                <Logo
                  src="https://firebasestorage.googleapis.com/v0/b/buttheads-production.appspot.com/o/walletconnect-logo.svg?alt=media&token=c21f3c9b-5543-4fa2-b3a1-862477f95ba6"
                  alt="WalletConnect"
                />
              </Inside>
            </Button>
            {/* <Button
              margin="1%"
              padding="1%"
              _hover={{ bg: "#000" }}
              backgroundColor="#141414"
              onClick={() => {
                activate(walletlink);
                setmodalopen(false);
              }}
            >
              <Inside>
                <div>Coinbase</div>
                <Logo
                  src="https://firebasestorage.googleapis.com/v0/b/buttheads-production.appspot.com/o/walletconnect-logo.svg?alt=media&token=c21f3c9b-5543-4fa2-b3a1-862477f95ba6"
                  alt="WalletConnect"
                />
              </Inside>
            </Button> */}
          </Wrapper>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ConnectWallet;

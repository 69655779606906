import { FaDiscord, FaTwitter } from "react-icons/fa";
import { GiSnake } from "react-icons/gi";
import { Button, Icon } from "@chakra-ui/react";
import OpenSeaTransparent from "./OpenSeaIcon";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "@chakra-ui/react";

const Wrapper = styled.div`
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  flex-wrap: wrap;
`;

export const flex = css`
  flex: 1;
  display: flex;
  align-items: center;
`;

const ConnectWrapper = styled.div`
  ${flex};
  justify-content: end;
  align-items: center;
  margin-left: 4%;
  @media (max-width: 540px) {
    display: none;
  }
`;

const LogoWrapper = styled.a`
  ${flex};
  margin-right: 4%;
  text-decoration: none;
  color: inherit;
  min-width: 240px;
  width: 100%;
  @media (max-width: 540px) {
    justify-content: center;
  }
  h1 {
    font-size: 1rem;
    font-weight: 800;
    @media (max-width: 540px) {
      display: none;
    }
    margin-left: 2%;
  }
`;

const Logo = styled.div`
  max-width: 50px;
  @media (max-width: 540px) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const Logo2 = styled.div`
  max-width: 90px;
  @media (min-width: 540px) {
    display: none;
  }
  margin-bottom: 2%;
  img {
    width: 100%;
    height: auto;
  }
`;

const DefaultLink = ({ href, children }) => (
  <Link
    _hover={{ color: "#F86663" }}
    rel="noreferrer"
    href={href}
    color="white"
    margin="0 2%"
    display={"flex"}
    justifyContent={"center"}
  >
    {children}
  </Link>
);

const Header = () => (
  <Wrapper>
    <LogoWrapper href="/">
      <Logo>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/eldape.png?alt=media&token=7b31257e-a3b7-4f97-9b9b-58fd82a48f7f"
          alt="logo"
        />
      </Logo>
      <Logo2>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/logo-ear.png?alt=media&token=bc7990cb-f0d9-4264-9931-aa10dcc5cd50"
          alt="logo"
        />
      </Logo2>
      <h1>
        ELDERLY APE
        <br /> RETIREMENT CLUB
      </h1>
    </LogoWrapper>
    <ConnectWrapper>
      <Button
        margin="0 1%"
        _hover={{ color: "#F86663" }}
        onClick={() => window.open("/closet", "_self")}
        variant="outline"
        size="sm"
      >
        CLOSET
      </Button>
      <Button
        margin="0 1%"
        _hover={{ color: "#F86663" }}
        onClick={() => window.open("/stats", "_self")}
        variant="outline"
        size="sm"
      >
        STATS
      </Button>
      <Button
        margin="0 1%"
        _hover={{ color: "#F86663" }}
        onClick={() => window.open("/rarity", "_self")}
        variant="outline"
        size="sm"
      >
        RARITY
      </Button>
      <DefaultLink href="/snake">
        <Icon width={6} height={6} as={GiSnake} />
      </DefaultLink>
      <DefaultLink href="https://twitter.com/EARClubNFT">
        <Icon width={6} height={6} as={FaTwitter} />
      </DefaultLink>
      <DefaultLink href="https://discord.gg/earc">
        <Icon width={6} height={6} as={FaDiscord} />
      </DefaultLink>
      <DefaultLink href="https://opensea.io/collection/earc">
        <Icon width={6} height={6} margin="0 4% 0 2%" as={OpenSeaTransparent} />
      </DefaultLink>
    </ConnectWrapper>
  </Wrapper>
);

export default Header;

import { Global, css } from "@emotion/react";

import styled from "@emotion/styled";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderImage from "./components/HeaderImage";
import Intro from "./components/Intro";
import Roadmap from "./components/RoadMap";
import Team from "./components/Team";
import { Routes, Route } from "react-router-dom";
import Specs from "./components/Specs";
import SnakeIt from "./components/Snake";
import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { firebaseConfig } from "./lib/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import { CHAINDATA } from "./constants";
import SoldOut from "./components/SoldOut";
import Rarity from "./components/Rarity";
import Stats from "./components/Stats";
import Closet from "./components/Closet";

const global = css`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;800;900&display=swap");
`;

export const Content = styled.div`
  background-color: #000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;

const Wrapper = styled.div`
  background-color: #000;
  min-height: 100vh;
`;

function App() {
  const [fbIsInit, setFbIsInit] = useState(false);
  useEffect(() => {
    if (!fbIsInit) {
      initializeApp(firebaseConfig);
      setFbIsInit(true);
    }
  }, [fbIsInit]);

  return (
    <Wrapper>
      <Global styles={global} />
      <Routes>
        <Route path="/rarity" element={<Rarity fbIsInit={fbIsInit} />} />
        <Route path="/stats" element={<Stats fbIsInit={fbIsInit} />} />
        <Route path="/snake" element={<SnakePage fbIsInit={fbIsInit} />} />
        <Route path="/closet" element={<Closet fbIsInit={fbIsInit} />} />
        <Route path="/" element={<HomePage fbIsInit={fbIsInit} />} />
      </Routes>
    </Wrapper>
  );
}

const SnakePage = ({ fbIsInit }) => {
  useEffect(() => {
    if (fbIsInit) {
      const analytics = getAnalytics();
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: "/snake",
        page_title: "Snake",
      });
    }
  }, [fbIsInit]);

  return (
    <Content>
      <Header />
      <SnakeIt />
    </Content>
  );
};

const NotAffilated = styled.p`
  margin-bottom: 4%;
  font-weight: 100;
  padding: 0 5%;
  font-size: 0.8em;
`;
const Verified = styled.p`
  width: 100%;
  padding: 3% 5%;
  text-align: center;
  font-weight: 300;
  font-size: 0.8em;
  a {
    font-weight: 800;
    text-decoration: underline;
  }
`;

const contractAddress = "0x9ee36cD3E78bAdcAF0cBED71c824bD8C5Cb65a8C";

const ButtonGalleryWrapper = styled.div`
  padding: 5%;
  border-bottom: 1px solid white;
  width: 90%;
  text-align: center;
  @media (min-width: 580px) {
    display: none;
  }
`;

const HomePage = ({ fbIsInit }) => {
  useEffect(() => {
    if (fbIsInit) {
      const analytics = getAnalytics();
      logEvent(analytics, "page_view", {
        page_location: window.location.href,
        page_path: "/",
        page_title: "Homepage",
      });
    }
  }, [fbIsInit]);

  return (
    <>
      <Content>
        <Header />
        <HeaderImage />
        <ButtonGalleryWrapper>
          <Button
            margin="0 1%"
            backgroundColor={"#F86663"}
            onClick={() => window.open("/stats", "_self")}
          >
            STATS
          </Button>
          <Button
            margin="0 1%"
            backgroundColor={"#F86663"}
            onClick={() => window.open("/closet", "_self")}
          >
            CLOSET
          </Button>
          <Button
            margin="0 1%"
            backgroundColor={"#F86663"}
            onClick={() => window.open("/rarity", "_self")}
          >
            RARITY
          </Button>
        </ButtonGalleryWrapper>
        <Intro />
        <SoldOut />
        <Verified>
          VERIFIED SMART CONTRACT ADDRESS:{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href={`${CHAINDATA.blockExplorer}address/${contractAddress}`}
          >
            {contractAddress}
          </a>
        </Verified>
        <Roadmap />
        <Team />
        <Specs />
        <NotAffilated>
          * We are not affiliated with the Bored Ape Yacht Club. (But our
          communities can collaborate).
        </NotAffilated>
        <Footer />
      </Content>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;

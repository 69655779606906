import { Button, Heading } from "@chakra-ui/react";
import Snake from "react-simple-snake";
import styled from "@emotion/styled";

const SnakeWrap = styled.div`
  width: 100%;
  padding: 2% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SnakeIt = () => (
  <SnakeWrap>
    <Heading
      color={"#F86663"}
      fontWeight={800}
      fontSize={"1.6em"}
      fontStyle={"italic"}
      textAlign={"center"}
      marginBottom={'2%'}
    >
      SNAKE (JUST FOR FUN)
    </Heading>
    <Snake percentageWidth={80} />
    <Button
      width={200}
      marginTop={100}
      backgroundColor={"#F86663"}
      _hover={{ bg: "#F75250" }}
      color={"white"}
      variant="outline"
      onClick={() => window.open("/", "_self")}
    >
      GO BACK
    </Button>
  </SnakeWrap>
);

export default SnakeIt;

import { UnorderedList, ListItem, Heading } from "@chakra-ui/react";

import roadmap from "./../content/roadmap2.json";
import { padding, Wrapper as Content } from "./Intro";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  ${padding}
  width: 95%;
`;

const Headline = styled.h2`
  font-size: 1.6em;
  ${padding}
  font-weight: 800;
  font-style: italic;
  color: #F86663;
  text-align:center;
  margin-bottom: 2.5%;
`;

const MyListItem = styled(ListItem)`
margin-bottom: 2%;
  h3 {
    font-weight: 600;
    margin-bottom: 1%;
  }
  p {
    font-weight: 300;
  }
`;

const MyPic = styled.div`
width: 100%;
margin-bottom: 2.5%;
  img {
    width: 100%;
    height: auto;
  }
`

const Roadmap = () => (
  <Wrapper>
    <Headline >RETIREMENT PLAN 2.0</Headline>
    <MyPic>
      <img alt="roadmap" src="https://firebasestorage.googleapis.com/v0/b/ear-club.appspot.com/o/road_map_fin-tiny.png?alt=media&token=c4b666d5-9f6e-4e43-bf45-3961d910c8d7" />
    </MyPic>
    <Content>
      <UnorderedList listStyleType={"none"} marginBottom='2%' minWidth={250} flex={2} spacing={10} fontSize={16}>
        {roadmap.map((sectionPoint) => {
          return (
            <ListItem key={sectionPoint.section}>
              <Heading fontWeight={800} fontStyle={'italic'} marginBottom={"2%"} color="#F86663" size={"md"}>{sectionPoint.header}</Heading>
              <UnorderedList>
              {sectionPoint.children.map(roadmapPoint => (
                  <MyListItem key={roadmapPoint.key}>
                    <h3>{roadmapPoint.header}</h3>
                    <p dangerouslySetInnerHTML={{ __html: roadmapPoint.content}} />
                  </MyListItem>
                ))}
              </UnorderedList>
 
            </ListItem>
          );
        })}
      </UnorderedList>
    </Content>
  </Wrapper>
);

export default Roadmap;
